<mat-card class="relative fx-fill fx-card">
	<mat-toolbar
		color="primary"
		class="fx-card-toolbar fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8 fx-height-42">
		<mat-icon fontIcon="mdi-contacts" fontSet="mdi"></mat-icon>
		<span>{{ title }}</span>

		<span class="fx-grow-1"></span>

		@if (patientService.patientSelected) {
			<span>{{ patientService.patientSelected | async }}</span>
		}

		<span class="fx-grow-1"></span>
		<div class="fx-layout-row-nowrap fx-content-center fx-items-start">
			@if (
				profile &&
				(profile.createPatient !== 'NONE' || profile.patientModule) &&
				(patientService.patientSelected | async) === false
			) {
				<button
					(click)="createNewPatient()"
					[matTooltip]="'ADD' | translate"
					class="btn-top"
					mat-button>
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
					{{ 'ADD' | translate }}
				</button>
			}
		</div>
	</mat-toolbar>

	<div class="relative fx-fill-height">
		<router-outlet></router-outlet>
	</div>
</mat-card>
