import { Component } from '@angular/core';
import { PatientService } from './patient.service';
import { Router } from '@angular/router';

@Component({
	selector: 'ft-patient',
	templateUrl: './patient.component.html',
	styleUrls: ['./patient.component.scss'],
})
export class PatientComponent {
	profile: any;
	title = 'Patients';

	constructor(
		public patientService: PatientService,
		private _router: Router
	) {
		this.profile = JSON.parse(localStorage.getItem('profile'));
	}

	createNewPatient() {
		this._router.navigate(['/patients/folder', 0]);
	}
}
