<div class="fx-layout-column-nowrap fx-fill-height relative">
	<div
		class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
		<mat-form-field subscriptSizing="dynamic" class="fx-grow-1">
			<mat-icon
				matIconPrefix
				class="prefix-icon"
				fontIcon="mdi-magnify"
				fontSet="mdi"
				tabindex="-1"></mat-icon>
			<input matInput #filter placeholder="{{ 'SEARCH' | translate }}" />
		</mat-form-field>

		@if (
			selection.hasValue() &&
			selection.selected.length > 1 &&
			profile &&
			(profile.mergePatient !== 'NONE' || profile.patientModule)
		) {
			<button
				mat-button
				(click)="mergePatients()"
				[matTooltip]="'MERGE' | translate">
				<mat-icon
					class="merge-icon"
					fontIcon="mdi-merge"
					fontSet="mdi"></mat-icon>
			</button>
		}

		@if (
			profile &&
			(profile.exportPatient !== 'NONE' || profile.patientModule)
		) {
			<button
				mat-button
				(click)="exportCSV()"
				[matTooltip]="'EXPORTCSV' | translate"
				id="save">
				<mat-icon
					class="export-csv-icon"
					fontIcon="mdi-cloud-download"
					fontSet="mdi"></mat-icon>
			</button>
		}

		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon fontIcon="mdi-dots-vertical" fontSet="mdi"></mat-icon>
		</button>
		<mat-menu #menu="matMenu" [overlapTrigger]="true">
			@for (item of availableColumns; track item) {
				<button
					(click)="$event.stopPropagation(); toggleColumn(item)"
					mat-menu-item>
					<mat-icon
						fontIcon="{{
							item.hidden
								? 'mdi-checkbox-blank-circle-outline'
								: 'mdi-check-circle'
						}}"
						fontSet="mdi"
						style="color: #1565c0"></mat-icon>
					{{ item.header | translate }}
				</button>
			}
		</mat-menu>
	</div>

	<div class="table-view fx-overflow-auto">
		<mat-table
			[dataSource]="dataSource"
			[trackBy]="trackById"
			matSort
			matSortActive="created_date"
			matSortDirection="desc"
			[matSortDisableClear]="true">
			<!--Table-->

			<!-- Checkbox Column -->
			<ng-container matColumnDef="select">
				<mat-header-cell *matHeaderCellDef>
					<mat-checkbox
						(change)="$event ? toggleAllRows() : null"
						[checked]="selection.hasValue() && isAllSelected()"
						[indeterminate]="
							selection.hasValue() && !isAllSelected()
						"
						[aria-label]="checkboxLabel()">
					</mat-checkbox>
				</mat-header-cell>
				<mat-cell mat-cell *matCellDef="let row">
					<mat-checkbox
						(click)="$event.stopPropagation()"
						(change)="$event ? selection.toggle(row) : null"
						[checked]="selection.isSelected(row)"
						[aria-label]="checkboxLabel(row)">
					</mat-checkbox>
				</mat-cell>
			</ng-container>

			@for (col of displayedColumns; track col) {
				<ng-container
					[class.hidden]="col.hidden"
					matColumnDef="{{ col.label }}">
					<mat-header-cell
						*matHeaderCellDef
						mat-sort-header="{{ col.sortField }}"
						[disabled]="!col.sortable"
						[matTooltip]="col.unit">
						{{ col.header | translate }}
					</mat-header-cell>

					@switch (col.type) {
						@case ('date') {
							<mat-cell *matCellDef="let row">
								{{ formatDate(row[col.label]) }}</mat-cell
							>
						}
						@case ('conf_data') {
							<mat-cell *matCellDef="let row">
								{{ showConfData(row, col.label) }}</mat-cell
							>
						}
						@case ('ID') {
							@switch (col.label) {
								@case ('genderId') {
									<mat-cell *matCellDef="let row">
										<mat-icon
											fontSet="mdi"
											[matTooltip]="
												genders[row[col.value]]
											"
											[style.color]="
												genders[row[col.value]] === 'F'
													? '#E91E63'
													: '#03A9F4'
											"
											[fontIcon]="
												genders[row[col.value]] === 'F'
													? 'mdi-gender-female'
													: genders[
																row[col.value]
														  ] === 'M'
														? 'mdi-gender-male'
														: ''
											"></mat-icon>
									</mat-cell>
								}
								@case ('titleId') {
									<mat-cell *matCellDef="let row">
										{{ titles[row[col.value]] }}</mat-cell
									>
								}
								@case ('maritalStatusId') {
									<mat-cell *matCellDef="let row">
										{{
											maritalStatuses[row[col.value]]
										}}</mat-cell
									>
								}
								@case ('confidentialityId') {
									<mat-cell *matCellDef="let row">
										<div
											class="ft-chip"
											[ngStyle]="{
												backgroundColor:
													getConfidentialityColor(
														confidentialities[
															row[col.value]
														]
													)
											}">
											{{
												confidentialities[
													row[col.value]
												] | translate
											}}
										</div>
									</mat-cell>
								}
								@default {
									<mat-cell *matCellDef="let row"></mat-cell>
								}
							}
						}
						@default {
							<mat-cell *matCellDef="let row">
								{{ row[col.label] }}</mat-cell
							>
						}
					}
				</ng-container>
			}

			<!--actions-->
			<ng-container matColumnDef="action" [stickyEnd]="true">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell
					*matCellDef="let row"
					class="fx-layout-row-nowrap fx-content-end fx-items-center">
					@if (can(row, 'updatePatient')) {
						<button
							class="edit-patient-icon"
							mat-icon-button
							[matTooltip]="'DETAILS' | translate"
							(click)="
								$event.stopPropagation(); editPatient(row)
							">
							<mat-icon
								fontIcon="mdi-pencil"
								fontSet="mdi"></mat-icon>
						</button>
					}
					@if (can(row, 'deletePatient')) {
						<button
							class="delete-patient-icon"
							mat-icon-button
							(click)="
								$event.stopPropagation(); deletePatient(row)
							">
							<mat-icon
								fontIcon="mdi-delete"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-cell>
			</ng-container>

			<!--header-->
			<mat-header-row
				*matHeaderRowDef="
					columnsToDisplay;
					sticky: true
				"></mat-header-row>
			<mat-row
				[@rowsAnimation]=""
				*matRowDef="let row; columns: columnsToDisplay"></mat-row>
		</mat-table>

		<div
			class="fx-table-empty"
			[style.display]="resultsLength === 0 ? '' : 'none'">
			@if (isLoadingResults) {
				<div>
					<mat-spinner
						[diameter]="50"
						[strokeWidth]="3"
						color="warn"></mat-spinner>
				</div>
			} @else {
				{{ 'NOFOUND' | translate }}
			}
		</div>
	</div>

	<mat-paginator
		[length]="resultsLength"
		[pageIndex]="0"
		[pageSize]="20"
		[pageSizeOptions]="[5, 10, 15, 20, 50]"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
@if (downloading) {
	<div
		class="fx-layout-row fx-content-center fx-items-center"
		style="
			position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			background: rgba(0, 0, 0, 0.4);
			z-index: 9999;
		">
		<div
			class="fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-12"
			style="
				height: 86px;
				width: 360px;
				border-radius: 8px;
				background: rgb(255 255 255);
				color: #585858;
			">
			<mat-spinner [diameter]="30" [strokeWidth]="2"></mat-spinner>
			<h3>{{ 'EXPORTING' | translate }}</h3>
		</div>
	</div>
}
