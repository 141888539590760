@if (patient) {
	<div
		class="fx-layout-column fx-items-center fx-overflow-auto fx-padding-16"
		id="patient">
		<div class="gd-patient-layout fx-gap-8">
			<div class="ft-card patient">
				<div
					class="ft-card-title fx-layout-row-nowrap fx-items-center fx-gap-8">
					<mat-icon
						class="patient-detail-icon"
						fontSet="mdi"
						fontIcon="mdi-card-account-details"></mat-icon>
					<span>{{ 'PATIENT_FILE' | translate }}</span>
				</div>
				<form class="ft-card-content" [formGroup]="patientForm">
					<div class="ft-patient-card fx-grow-1 fx-overflow-auto">
						<div class="patient-top fx-gap-8">
							<div
								class="ft-side-area fx-layout-column fx-items-center">
								<img
									class="ft-profile-img"
									[src]="patientPhotoUrl"
									alt="Patient picture" />
								<button
									mat-icon-button
									style="top: -32px"
									[matTooltip]="'EDIT_IMAGE' | translate"
									(click)="
										$event.stopPropagation(); editImage()
									"
									tabindex="-1">
									<mat-icon
										fontIcon="mdi-camera"
										fontSet="mdi"></mat-icon>
								</button>
							</div>

							<div class="center-sm">
								<div class="fx-layout-row fx-gap-8">
									<mat-form-field>
										<mat-label>{{
											'PATIENTID' | translate
										}}</mat-label>
										<input
											matInput
											placeholder="{{
												'PATIENTID' | translate
											}}"
											formControlName="externalPatientID" />
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'TITLE' | translate
										}}</mat-label>
										<mat-select
											placeholder="{{
												'TITLE' | translate
											}}"
											formControlName="titleId">
											@for (
												title of titles;
												track title.id
											) {
												<mat-option
													[value]="title.id"
													>{{
														title.value
													}}</mat-option
												>
											}
										</mat-select>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'LASTNAME' | translate
										}}</mat-label>
										<input
											matInput
											placeholder="{{
												'LASTNAME' | translate
											}}"
											formControlName="lastName"
											required />
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'FIRSTNAME' | translate
										}}</mat-label>
										<input
											matInput
											placeholder="{{
												'FIRSTNAME' | translate
											}}"
											formControlName="firstName"
											required />
									</mat-form-field>
								</div>

								<div
									class="fx-layout-row fx-content-start fx-items-end fx-gap-8">
									<mat-form-field>
										<mat-label>{{
											'CIN' | translate
										}}</mat-label>
										<input
											matInput
											placeholder="{{
												'CIN' | translate
											}}"
											formControlName="cin" />
									</mat-form-field>
									<mat-form-field>
										<mat-label>{{
											'NATIONAL_NUMBER' | translate
										}}</mat-label>
										<input
											formControlName="nationalNumber"
											matInput
											placeholder="{{
												'NATIONAL_NUMBER' | translate
											}}" />
									</mat-form-field>

									<div
										class="fx-layout-row fx-content-start fx-gap-8">
										<mat-form-field style="width: 158px">
											<mat-label>{{
												'DATEOFBIRTH' | translate
											}}</mat-label>
											<input
												matInput
												formControlName="dateOfBirth"
												[matDatepicker]="picker"
												(change)="changeAge()"
												placeholder="{{
													'DATEOFBIRTH' | translate
												}}" />
											<mat-datepicker-toggle
												matSuffix
												[for]="
													picker
												"></mat-datepicker-toggle>
											<mat-datepicker
												(closed)="changeAge()"
												#picker></mat-datepicker>
										</mat-form-field>

										<div
											class="fx-layout-row-nowrap fx-content-start fx-gap-2"
											[formGroup]="ageForm">
											<mat-form-field style="width: 90px">
												<input
													type="number"
													matInput
													placeholder="{{
														'0' | translate
													}}"
													formControlName="years"
													(keyup)="changeDate()" />
												<span matTextSuffix>{{
													'YEARS' | translate
												}}</span>
											</mat-form-field>

											<mat-form-field style="width: 92px">
												<input
													type="number"
													matInput
													placeholder="{{
														'0' | translate
													}}"
													formControlName="months"
													(keyup)="changeDate()" />
												<span matTextSuffix>{{
													'MONTH' | translate
												}}</span>
											</mat-form-field>

											<mat-form-field style="width: 80px">
												<input
													type="number"
													matInput
													placeholder="{{
														'0' | translate
													}}"
													formControlName="days"
													(keyup)="changeDate()" />
												<span matTextSuffix>{{
													'DAY' | translate
												}}</span>
											</mat-form-field>
										</div>
									</div>
								</div>

								<div
									class="fx-layout-row fx-content-start fx-items-end fx-gap-8">
									<mat-form-field>
										<mat-label>{{
											'MARITALSTATUS' | translate
										}}</mat-label>
										<mat-select
											placeholder="{{
												'MARITALSTATUS' | translate
											}}"
											formControlName="maritalStatusId">
											@for (
												ms of maritalStatuses;
												track ms.id
											) {
												<mat-option [value]="ms.id">{{
													ms.description
												}}</mat-option>
											}
										</mat-select>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'PATIENT_CLASS' | translate
										}}</mat-label>
										<mat-select
											[placeholder]="
												'PATIENT_CLASS' | translate
											"
											formControlName="patientClassId">
											@for (
												item of patientClasses;
												track item.id
											) {
												<mat-option [value]="item.id">{{
													item.description
												}}</mat-option>
											}
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>

						<div class="fx-layout-row fx-content-space-between">
							<div
								class="fx-layout-column relative fix-top fx-content-space-between">
								<h5 style="margin: 0 8px">
									{{ 'GENDER' | translate }}
								</h5>

								<mat-radio-group
									class="fx-layout-row fx-content-start fx-items-end"
									formControlName="genderId">
									@for (option of genders; track option.id) {
										<mat-radio-button
											class="fx-grow-1"
											[value]="option.id">
											{{
												option.value === 'U'
													? ('UNKNOWN' | translate)
													: option.value
											}}
										</mat-radio-button>
									}
								</mat-radio-group>
							</div>

							<div style="width: fit-content">
								<div
									class="relative fix-top fx-layout-column fx-content-space-between">
									<h5 style="margin: 0 8px">
										{{ 'CONFIDENTIALITY' | translate }}
									</h5>
									<mat-radio-group
										class="fx-layout-row fx-content-start fx-items-end"
										formControlName="confidentialityId">
										@for (
											option of confidentialities;
											track option.id
										) {
											<mat-radio-button
												class="fx-grow-1"
												[value]="option.id"
												>{{ option.description }}
											</mat-radio-button>
										}
									</mat-radio-group>
								</div>
							</div>

							<div style="width: fit-content">
								<div
									class="relative fix-top fx-layout-column fx-content-space-between">
									<h5 style="margin: 0 8px">
										{{ 'DEBITER' | translate }}
									</h5>

									<mat-radio-group
										class="fx-layout-row fx-content-start fx-items-end"
										formControlName="debiter">
										@for (
											option of debiters;
											track option
										) {
											<mat-radio-button
												class="fx-grow-1"
												[value]="option"
												>{{ option | translate }}
											</mat-radio-button>
										}
									</mat-radio-group>
								</div>
							</div>
						</div>

						<mat-divider class="divider-padding"></mat-divider>

						<div class="subtitle">{{ 'CONTACT' | translate }}</div>

						<div class="fx-layout-row-nowrap fx-gap-8">
							<mat-form-field class="fx-grow-1">
								<mat-label>{{ 'PHONE' | translate }}</mat-label>
								<input
									matInput
									placeholder="{{ 'PHONE' | translate }}"
									formControlName="phone"
									type="tel" />
							</mat-form-field>
							<mat-form-field class="fx-grow-1">
								<mat-label>{{ 'EMAIL' | translate }}</mat-label>
								<input
									matInput
									placeholder="{{ 'EMAIL' | translate }}"
									formControlName="email" />
							</mat-form-field>
						</div>

						<div class="fx-layout-column" [formGroup]="addressForm">
							<div class="fx-layout-row-nowrap fx-gap-8">
								<mat-form-field class="fx-grow-1">
									<mat-label>{{
										'STREET' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'STREET' | translate }}"
										formControlName="street" />
								</mat-form-field>

								<mat-form-field style="width: 200px">
									<mat-label>{{
										'POSTAL_CODE' | translate
									}}</mat-label>
									<input
										[formControl]="postalCodeControl"
										[matAutocomplete]="postalCodeAuto"
										[placeholder]="
											'POSTAL_CODE' | translate
										"
										matInput />
									<mat-autocomplete
										#postalCodeAuto="matAutocomplete"
										(optionSelected)="
											onChangePostalCode($event)
										"
										[autoActiveFirstOption]="true">
										@for (
											city of filteredPostalCodes;
											track city
										) {
											<mat-option
												[value]="city"
												class="option-line">
												{{ city.code }} -
												{{ city.location }}
											</mat-option>
										}
									</mat-autocomplete>
								</mat-form-field>
							</div>

							<div class="fx-layout-row fx-gap-8">
								<mat-form-field class="fx-grow-1">
									<mat-label>{{
										'COUNTRY' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{
											'COUNTRY' | translate
										}}"
										formControlName="country" />
								</mat-form-field>

								<mat-form-field style="width: 200px">
									<mat-label>{{
										'CITY' | translate
									}}</mat-label>
									<input
										[formControl]="cityControl"
										[matAutocomplete]="cityAuto1"
										[placeholder]="'CITY' | translate"
										matInput />
									<mat-autocomplete
										#cityAuto1="matAutocomplete"
										(optionSelected)="
											onChangePostalCode($event)
										"
										[autoActiveFirstOption]="true">
										@for (
											city of filteredPostalCodes;
											track city
										) {
											<mat-option
												[value]="city"
												class="option-line"
												>{{ city.location }}
											</mat-option>
										}
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</div>

						@if (patientForm.get('debiter').value !== 'PATIENT') {
							<mat-divider class="divider-padding"></mat-divider>
							<div class="subtitle">
								{{ 'INSURANCE' | translate }}
							</div>
							<div [formGroup]="insuranceForm">
								<div
									class="fx-layout-row fx-content-start fx-items-center fx-gap-8">
									<mat-form-field style="width: 360px">
										<input
											[matAutocomplete]="organismAuto"
											[placeholder]="
												'INS_NAME' | translate
											"
											aria-label="Insurance organism"
											formControlName="organismName"
											matInput
											type="text" />
										<mat-autocomplete
											#organismAuto="matAutocomplete"
											(optionSelected)="
												onSelectOrganism()
											"
											[autoActiveFirstOption]="true">
											@for (
												organism of filteredOrganisms
													| async;
												track organism
											) {
												<mat-option
													[value]="organism.name"
													>{{ organism.code }} -
													{{ organism.name }}
												</mat-option>
											}
										</mat-autocomplete>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'INS_CODE' | translate
										}}</mat-label>
										<input
											matInput
											[placeholder]="
												'INS_CODE' | translate
											"
											formControlName="code" />
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'CONVENTION' | translate
										}}</mat-label>
										<mat-select
											[placeholder]="
												'CONVENTION' | translate
											"
											[compareWith]="compareConvention"
											formControlName="conventionName">
											@for (
												convention of conventions;
												track convention
											) {
												<mat-option
													[value]="convention">
													{{ convention }}
												</mat-option>
											}
										</mat-select>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{
											'COVER_NUMBER' | translate
										}}</mat-label>
										<input
											[placeholder]="
												'COVER_NUMBER' | translate
											"
											formControlName="coverNumber"
											matInput />
									</mat-form-field>

									<div style="width: fit-content">
										<div
											class="fx-layout-column relative fix-top fx-content-space-between">
											<h5 style="margin: 0 8px">
												{{
													'RELATION_WITH_INSURED'
														| translate
												}}
											</h5>

											<mat-radio-group
												class="fx-layout-row fx-content-start fx-items-end"
												formControlName="relationWithInsured">
												@for (
													option of [
														'HIMSELF',
														'PARTNER',
														'CHILD'
													];
													track option
												) {
													<mat-radio-button
														class="fx-grow-1"
														[value]="option">
														{{ option | translate }}
													</mat-radio-button>
												}
											</mat-radio-group>
										</div>
									</div>
								</div>

								@if (
									insuranceForm.get('relationWithInsured')
										.value !== 'HIMSELF'
								) {
									<div class="fx-layout-column-nowrap">
										<div class="fx-layout-row fx-gap-8">
											<mat-form-field>
												<mat-label>{{
													'TITLE' | translate
												}}</mat-label>
												<mat-select
													placeholder="{{
														'TITLE' | translate
													}}"
													formControlName="insuredTitle">
													@for (
														title of titles;
														track title.id
													) {
														<mat-option
															[value]="
																title.value
															"
															>{{
																title.value
															}}</mat-option
														>
													}
												</mat-select>
											</mat-form-field>

											<mat-form-field>
												<mat-label>{{
													'LAST_NAME' | translate
												}}</mat-label>
												<input
													matInput
													[placeholder]="
														'LAST_NAME' | translate
													"
													formControlName="insuredLastName" />
											</mat-form-field>

											<mat-form-field>
												<mat-label>{{
													'FIRST_NAME' | translate
												}}</mat-label>
												<input
													matInput
													[placeholder]="
														'FIRST_NAME' | translate
													"
													formControlName="insuredFirstName" />
											</mat-form-field>
										</div>

										<mat-form-field>
											<mat-label>{{
												'ADDRESS' | translate
											}}</mat-label>
											<textarea
												matInput
												[placeholder]="
													'ADDRESS' | translate
												"
												formControlName="address"></textarea>
										</mat-form-field>
									</div>
								}
							</div>
						}
					</div>
				</form>
			</div>

			<div class="fx-layout-column fx-content-start fx-gap-8 ft-right">
				<div class="ft-card gd-right">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-items-center fx-gap-8">
						<mat-icon
							class="medical-history-icon"
							fontIcon="mdi-dna"
							fontSet="mdi"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'MEDICAL_FILE' | translate
						}}</span>
					</div>
					<div class="fx-layout-column" [formGroup]="medicalForm">
						<mat-form-field>
							<mat-label>{{
								'REFERRING_PHYSICIAN' | translate
							}}</mat-label>
							<input
								[formControl]="referringPhysicianControl"
								[matAutocomplete]="refPhyList1"
								[placeholder]="
									'REFERRING_PHYSICIAN' | translate
								"
								matInput />
							<mat-autocomplete
								#refPhyList1="matAutocomplete"
								(optionSelected)="onChangeRefPhy($event)"
								[autoActiveFirstOption]="true">
								@for (
									user of filteredReferringPhysicians;
									track user
								) {
									<mat-option
										[value]="user"
										class="option-line">
										<div
											class="fx-layout-column fx-content-center fx-items-start"
											[style.lineHeight.px]="14"
											[style.fontSize.px]="12">
											<span
												class="fx-grow-1 option-line-1">
												{{ user.fullName }}</span
											>
											@if (user.codeAnam) {
												<span
													class="option-line-2 fx-grow-1">
													Code:
													{{ user.codeAnam || '-' }}
												</span>
											}
										</div>
									</mat-option>
								}
							</mat-autocomplete>
							<button
								(click)="
									$event.stopImmediatePropagation();
									addReferringPhysician()
								"
								mat-icon-button
								matIconSuffix>
								<mat-icon
									fontIcon="mdi-plus"
									fontSet="mdi"></mat-icon>
							</button>
							<button
								(click)="
									$event.stopImmediatePropagation();
									findReferringPhysician()
								"
								mat-icon-button
								matIconSuffix>
								<mat-icon
									fontIcon="mdi-magnify"
									fontSet="mdi"></mat-icon>
							</button>
						</mat-form-field>
						<mat-form-field>
							<mat-label>{{
								'SIDE_EFFECTS' | translate
							}}</mat-label>
							<mat-select
								[placeholder]="'SIDE_EFFECTS' | translate"
								formControlName="allergies"
								[multiple]="true">
								@for (effect of allergies; track effect) {
									<mat-option [value]="effect.value">{{
										effect.value
									}}</mat-option>
								}
							</mat-select>
							<button
								mat-icon-button
								matSuffix
								(click)="addAllergy($event)">
								<mat-icon
									fontIcon="mdi-plus"
									fontSet="mdi"></mat-icon>
							</button>
						</mat-form-field>

						<mat-form-field>
							<mat-label>{{
								'ADDITIONAL_PATIENT_HISTORY' | translate
							}}</mat-label>
							<textarea
								matInput
								placeholder="{{
									'ADDITIONAL_PATIENT_HISTORY' | translate
								}}"
								formControlName="additionalPatientHistory"></textarea>
						</mat-form-field>

						<mat-form-field>
							<mat-label>{{
								'SPECIALNEEDS' | translate
							}}</mat-label>
							<input
								matInput
								placeholder="{{ 'SPECIALNEEDS' | translate }}"
								formControlName="specialNeeds" />
						</mat-form-field>

						<mat-form-field>
							<mat-label>{{
								'MEDICALALERTS' | translate
							}}</mat-label>
							<input
								matInput
								placeholder="{{ 'MEDICALALERTS' | translate }}"
								formControlName="medicalAlerts" />
						</mat-form-field>

						<mat-form-field>
							<mat-label>{{
								'OBSERVATIONS' | translate
							}}</mat-label>
							<textarea
								matInput
								[placeholder]="'OBSERVATIONS' | translate"
								formControlName="observations"></textarea>
						</mat-form-field>

						<mat-divider class="divider-padding"></mat-divider>

						<div class="subtitle">{{ 'BIOMETRY' | translate }}</div>

						<div
							class="fx-layout-row fx-content-start fx-gap-8"
							[formGroup]="patientForm">
							<div
								class="fx-layout-row-nowrap fx-content-start fx-gap-8">
								<mat-form-field style="max-width: 110px">
									<mat-label>{{
										'HEIGHT' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'HEIGHT' | translate }}"
										formControlName="height"
										(keyup)="calculateIMCAndSC()" />
									<span matTextSuffix>cm</span>
								</mat-form-field>
								<mat-form-field style="max-width: 100px">
									<mat-label>{{
										'WEIGHT' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'WEIGHT' | translate }}"
										formControlName="weight"
										(keyup)="calculateIMCAndSC()" />
									<span matTextSuffix>Kg</span>
								</mat-form-field>
							</div>
							<div
								class="fx-layout-row-nowrap fx-content-start fx-gap-8">
								<mat-form-field style="max-width: 110px">
									<mat-label>{{
										'IMC' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'IMC' | translate }}"
										formControlName="imc" />
									<span matTextSuffix>Kg/m²</span>
								</mat-form-field>
								<mat-form-field style="max-width: 100px">
									<mat-label>{{
										'SC' | translate
									}}</mat-label>
									<input
										matInput
										placeholder="{{ 'SC' | translate }}"
										formControlName="sc" />
									<span matTextSuffix>m²</span>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="ft-card gd-right">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-items-center fx-gap-8">
						<mat-icon
							class="file-manager-icon"
							fontSet="mdi"
							fontIcon="mdi-folder"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'FILES_ATTACHED' | translate
						}}</span>
					</div>
					<ft-file-explorer
						[fileElements]="fileElements | async"
						[path]="currentPath"
						[patientID]="patientID"
						[modalMode]="false"
						[canNavigateUp]="canNavigateUp"
						(folderAdded)="addFolder($event)"
						(elementRemoved)="removeElement($event)"
						(navigatedDown)="navigateToFolder($event)"
						(navigatedUp)="navigateUp()"
						(uploadEvent)="afterUpload($event)"
						(elementRenamed)="renameElement($event)"
						(elementMoved)="moveElement($event)">
					</ft-file-explorer>
				</div>
			</div>

			<div
				class="fx-layout-column fx-content-start fx-gap-8 gd-fill-weight">
				<div class="ft-card">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-8">
						<mat-icon
							class="scheduled-exam-icon"
							fontSet="mdi"
							fontIcon="mdi-prescription"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'Imaging Orders' | translate
						}}</span>
						<span class="fx-grow-1"></span>
					</div>
					<div class="ft-table">
						<div class="ft-row ft-io-row ft-header">
							<div class="ft-cell">
								{{ 'Exam Date/Time' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Ordering Provider' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Entering organization' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Patient Class' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Procedure' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Order Status' | translate }}
							</div>
							<div class="ft-cell"></div>
						</div>

						@for (order of imagingOrders; track order) {
							<div class="ft-row ft-io-row">
								<div class="ft-cell">
									{{ order.startDateTime }}
								</div>
								<div class="ft-cell">
									{{ splitter(order.orderingProvider, 1, 2) }}
								</div>
								<div class="ft-cell">
									{{
										splitter(
											order.enteringOrganization,
											0,
											1
										)
									}}
								</div>
								<div class="ft-cell">
									{{
										patientClassesAbv[order.patientClass] ||
											'-'
									}}
								</div>
								<div class="ft-cell">
									{{
										splitter(order.universalServiceID, 1, 0)
									}}
								</div>
								<div class="ft-cell">
									{{ order.orderStatus }}
								</div>
								<div class="ft-cell">
									<button
										mat-icon-button
										[matTooltip]="'Edit' | translate"
										(click)="editImagingOrder(order)">
										<mat-icon
											fontSet="mdi"
											fontIcon="mdi-pencil"></mat-icon>
									</button>
									<button
										mat-icon-button
										[matTooltip]="'Schedule' | translate"
										(click)="scheduleImagingOrder(order)">
										<mat-icon
											fontSet="mdi"
											fontIcon="mdi-calendar"></mat-icon>
									</button>
								</div>
							</div>
						}
					</div>
				</div>
				<div class="ft-card">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-8">
						<mat-icon
							class="scheduled-exam-icon"
							fontSet="mdi"
							fontIcon="mdi-calendar-check"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'SCHEDULEDPROCEDURES' | translate
						}}</span>
						<span class="fx-grow-1"></span>

						@if (
							profile &&
							profile.schedulerModule &&
							profile.scheduleExam !== 'NONE'
						) {
							<button
								mat-button
								color="warn"
								(click)="scheduleNewOrder()">
								<mat-icon
									fontSet="mdi"
									fontIcon="mdi-calendar-plus"></mat-icon>
								{{ 'SCHEDULE_ORDER' | translate }}
							</button>
						}
					</div>
					<div class="ft-table">
						<div class="ft-row ft-sps-row ft-header">
							<div class="ft-cell">
								{{ 'SCHEDULED_DATE' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'PERFORMING_RADIOLOGIST' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'SCHEDULEDAET' | translate }}
							</div>
							<div class="ft-cell">{{ 'EXAM' | translate }}</div>
							<div class="ft-cell"></div>
						</div>

						@for (sps of scheduledProcedures; track sps) {
							<div class="ft-row ft-sps-row">
								<div class="ft-cell">
									{{ sps.date | date: 'dd/MM/yyyy HH:mm' }}
								</div>
								<div class="ft-cell">
									{{ sps.performingPhysician }}
								</div>
								<div class="ft-cell">{{ sps.aet }}</div>
								<div class="ft-cell">
									{{ sps.procedureCode || '-' }}
								</div>
								<div class="ft-cell">
									@if (isEditable(sps)) {
										<button
											mat-icon-button
											[matTooltip]="'EDIT' | translate"
											(click)="editSPS(sps)">
											<mat-icon
												fontSet="mdi"
												fontIcon="mdi-pencil"></mat-icon>
										</button>
									}

									@if (isFuture(sps)) {
										<button
											mat-icon-button
											[matTooltip]="
												'PRINT_RDV' | translate
											"
											(click)="printRDV(sps)">
											<mat-icon
												fontSet="mdi"
												fontIcon="mdi-printer"></mat-icon>
										</button>
									}

									@if (isEditable(sps)) {
										<button
											mat-icon-button
											[matTooltip]="'DELETE' | translate"
											(click)="deleteSPS(sps)">
											<mat-icon
												fontSet="mdi"
												fontIcon="mdi-delete"></mat-icon>
										</button>
									}
								</div>
							</div>
						}
					</div>
				</div>
				<div class="ft-card">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-8">
						<mat-icon
							class="rdv-icon"
							fontSet="mdi"
							fontIcon="mdi-calendar-question"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'APPOINTMENTS' | translate
						}}</span>
						<span class="fx-grow-1"></span>

						@if (
							profile &&
							profile.schedulerModule &&
							profile.createOrder !== 'NONE'
						) {
							<button
								(click)="createNewAppointment()"
								color="warn"
								mat-button>
								<mat-icon
									fontSet="mdi"
									fontIcon="mdi-plus-circle"></mat-icon>
								{{ 'ADD_APPOINTMENT' | translate }}
							</button>
						}
					</div>
					<div class="ft-table">
						<div class="ft-row ft-order-row ft-header">
							<div class="ft-cell">
								{{ 'REQUESTEDDATE' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'ORDERING_PROVIDER' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'REASON_FOR_EXAM' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'APPOINTMENT_STATUS' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'SOURCE' | translate }}
							</div>
							<div class="ft-cell"></div>
						</div>

						@for (apt of appointments; track apt) {
							<div class="ft-row ft-order-row">
								<div class="ft-cell">
									{{ apt.startDate | date: 'dd/MM/yyyy' }}
								</div>
								<div class="ft-cell">
									{{ apt.referringPhysicianFullName || '-' }}
								</div>
								<div class="ft-cell">
									{{ apt.appointmentReason || '-' }}
								</div>
								<div class="ft-cell">
									{{ apt.appointmentStatus | translate }}
								</div>
								<div class="ft-cell">
									{{ apt.appointmentSource || '-' }}
								</div>
								<div class="ft-cell">
									<button
										mat-icon-button
										[matTooltip]="
											'SCHEDULEMENU' | translate
										"
										(click)="onScheduleAppointment(apt)">
										<mat-icon
											class="mat-18"
											fontSet="mdi"
											fontIcon="mdi-calendar"></mat-icon>
									</button>
									<button
										mat-icon-button
										[matTooltip]="'DELETEMENU' | translate"
										(click)="onDeleteAppointments(apt)">
										<mat-icon
											class="mat-18"
											fontSet="mdi"
											fontIcon="mdi-delete"></mat-icon>
									</button>
								</div>
							</div>
						}
					</div>
				</div>
				<div class="ft-card">
					<div
						class="ft-card-title fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-8">
						<mat-icon
							class="rdv-icon"
							fontSet="mdi"
							fontIcon="mdi-account-injury"></mat-icon>
						<span class="fx-hide-sm fx-hide-xs">{{
							'Admissions' | translate
						}}</span>
						<span class="fx-grow-1"></span>
					</div>
					<div class="ft-table">
						<div class="ft-row ft-visit-row ft-header">
							<div class="ft-cell">
								{{ 'Visit Number' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Assigned Patient location' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Hospital Service' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Patient Class' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Attending Doctor' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Referring Doctor' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Admitting Doctor' | translate }}
							</div>
							<div class="ft-cell">
								{{ 'Discharge Date Time' | translate }}
							</div>
							<div class="ft-cell"></div>
						</div>
						@for (visit of visits; track visit) {
							<div class="ft-row ft-visit-row">
								<div class="ft-cell">
									{{ visit.visitNumber }}
								</div>
								<div class="ft-cell">
									@if (
										visit.pointOfCare !== 'null' ||
										visit.room !== 'null'
									) {
										<div>
											PoC: <b>{{ visit.pointOfCare }}</b
											>, Room: <b>{{ visit.room }}</b
											>, Bed: <b>{{ visit.bed }}</b
											>, Facility:
											<b>{{ visit.facility }}</b>
										</div>
									}
								</div>
								<div class="ft-cell">
									{{ visit.hospitalService }}
								</div>
								<div class="ft-cell">
									{{ patientClassesAbv[visit.patientClass] }}
								</div>
								<div class="ft-cell">
									{{ visit.attendingDoctor || '-' }}
								</div>
								<div class="ft-cell">
									{{ visit.referringDoctor }}
								</div>
								<div class="ft-cell">
									{{ visit.admittingDoctor || '-' }}
								</div>
								<div class="ft-cell">
									{{ visit.dischargeDateTime || '-' }}
								</div>
								<div class="ft-cell">
									<button
										mat-icon-button
										[matTooltip]="'VIEW' | translate">
										<mat-icon
											class="mat-18"
											fontSet="mdi"
											fontIcon="mdi-eye"></mat-icon>
									</button>
								</div>
							</div>
						}
					</div>
				</div>
			</div>

			<div class="ft-card gd-fill-weight">
				<div
					class="ft-card-title fx-layout-row-nowrap fx-items-center fx-gap-8">
					<mat-icon
						class="exam-history-icon"
						fontSet="mdi"
						fontIcon="mdi-clipboard-pulse"></mat-icon>
					<span>{{ 'PATIENT_WORKFLOW' | translate }}</span>
				</div>
				<div class="ft-table">
					<div class="ft-row ft-header">
						<div class="ft-cell">
							{{ 'ACCESSION_NUMBER' | translate }}
						</div>
						<div class="ft-cell">{{ 'AET' | translate }}</div>
						<div class="ft-cell">
							{{ 'PERFORMING_RADIOLOGIST' | translate }}
						</div>
						<div class="ft-cell">{{ 'U.' }}</div>
						<div class="ft-cell">
							{{ 'PATIENT_ARRIVAL' | translate }}
						</div>
						<div class="ft-cell">
							{{ 'START_TIME' | translate }}
						</div>
						<div class="ft-cell">{{ 'END_TIME' | translate }}</div>
						<div class="ft-cell">
							{{ 'EXAM_STATUS' | translate }}
						</div>
						<div class="ft-cell">
							{{ 'REPORT_STATUS' | translate }}
						</div>
						<div class="ft-cell">
							{{ 'NUM_OF_FILMS' | translate }}
						</div>
						<div class="ft-cell">
							{{ 'CONTRAST_PRODUCT' | translate }}
						</div>
						<div class="ft-cell"></div>
					</div>
					@for (exam of patientExams; track exam) {
						<div class="ft-row">
							<div class="ft-cell">
								{{ exam.accessionNumber }}
							</div>
							<div class="ft-cell">{{ exam.aet }}</div>
							<div class="ft-cell">
								{{ exam.performingPhysician }}
							</div>
							<div class="ft-cell">
								<mat-icon
									style="color: #b10011"
									fontSet="mdi"
									fontIcon="{{
										exam.urgent === true
											? 'mdi-ambulance'
											: ''
									}}"></mat-icon>
							</div>
							<div class="ft-cell">
								{{ exam.patientArrival | date: 'dd/MM/yyyy' }}
							</div>
							<div class="ft-cell">
								{{ exam.examStarted | date: 'HH:mm' }}
							</div>
							<div class="ft-cell">
								{{ exam.examFinished | date: 'HH:mm' }}
							</div>
							<div class="ft-cell">
								{{ exam.spsStatus | translate }}
							</div>
							<div
								class="ft-cell"
								[ngStyle]="{
									color: getColor(exam.reportStatus),
									'font-weight': 'bold'
								}">
								{{
									getReportStatus(exam.reportStatus)
										| translate
								}}
							</div>
							<div class="ft-cell">{{ exam.numberOfFilms }}</div>
							<div class="ft-cell">{{ exam.contrast }}</div>
							<div class="ft-cell">
								@if (
									exam.reportStatus !== 'None' &&
									profile &&
									profile.viewPatientReport
								) {
									<button
										mat-icon-button
										[matTooltip]="'EDIT_REPORT' | translate"
										(click)="openReportingTask(exam)">
										<mat-icon
											fontIcon="mdi-file-document"
											fontSet="mdi"></mat-icon>
									</button>
								}

								@if (canPrint(exam)) {
									<button
										mat-icon-button
										[matTooltip]="
											'DOWNLOAD_REPORT' | translate
										"
										(click)="downloadReport(exam)">
										<mat-icon
											fontIcon="mdi-cloud-download"
											fontSet="mdi"></mat-icon>
									</button>
								}

								@if (
									profile &&
									profile.viewPatientStudies !== 'NONE' &&
									exam.imagesAvailable &&
									exam.studyInstanceUID !== ''
								) {
									<button
										mat-icon-button
										[matTooltip]="
											'OPEN_IN_VIEWER' | translate
										"
										(click)="
											openInViewer(exam.studyInstanceUID)
										">
										<mat-icon
											fontIcon="mdi-eye"
											fontSet="mdi"></mat-icon>
									</button>
								}

								@if (
									profile &&
									profile.viewPatientStudies !== 'NONE' &&
									exam.imagesAvailable &&
									exam.studyInstanceUID !== ''
								) {
									<a
										mat-icon-button
										[matTooltip]="
											'OPEN_IN_OSIRIX' | translate
										"
										(click)="openInOsirix(exam)">
										<mat-icon
											fontIcon="mdi-eye"
											fontSet="mdi"></mat-icon>
									</a>
								}
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	</div>
} @else {
	<div class="fx-layout-row fx-fill fx-content-center fx-items-center">
		<mat-spinner [strokeWidth]="3"></mat-spinner>
	</div>
}

<div
	class="fx-layout-row-nowrap fx-content-center fx-items-center fx-height-42 fx-gap-8 fx-fill-width">
	<button
		class="close-button"
		(click)="goBack()"
		color="warn"
		mat-raised-button>
		{{ 'BACK_TO_LIST' | translate }}
	</button>
	<button
		class="save-and-close-button"
		(click)="savePatientAndBack()"
		color="primary"
		mat-raised-button
		[disabled]="
			patientForm.invalid || insuranceForm.invalid || medicalForm.invalid
		">
		{{ 'SAVE_AND_BACK' | translate }}
	</button>
	<button
		class="save-button"
		(click)="savePatient()"
		[tabIndex]="1"
		color="primary"
		mat-raised-button
		[disabled]="
			patientForm.invalid || insuranceForm.invalid || medicalForm.invalid
		">
		{{ 'SAVE' | translate }}
	</button>
</div>
