/**
 * Created by MBX on 7/12/2017.
 */
export const PAT_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
		type: 'string',
		sortField: 'id',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: true,
		searchable: true,
		order: 1,
		required: false,
	},
	{
		label: 'externalPatientID',
		header: 'PATIENTID',
		value: 'externalPatientID',
		type: 'string',
		sortField: 'external_patientid',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		order: 1,
		required: true,
	},
	{
		label: 'titleId',
		header: 'TITLE',
		value: 'titleId',
		type: 'ID',
		sortField: 'title_id',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		order: 2,
		required: true,
	},
	{
		label: 'genderId',
		header: 'GENDER',
		value: 'genderId',
		type: 'ID',
		sortField: 'gender_id',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: false,
		searchable: true,
		order: 5,
		required: true,
	},
	{
		label: 'maritalStatusId',
		header: 'MARITALSTATUS',
		value: 'maritalStatusId',
		type: 'ID',
		sortField: 'marital_status_id',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: false,
		searchable: true,
		order: 7,
		required: true,
	},
	{
		label: 'weight',
		header: 'WEIGHT',
		value: 'weight',
		type: 'string',
		sortField: 'weight',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: true,
		searchable: true,
		order: 9,
		required: true,
	},
	{
		label: 'height',
		header: 'HEIGHT',
		value: 'height',
		type: 'string',
		sortField: 'height',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: true,
		searchable: true,
		order: 10,
		required: true,
	},
	{
		label: 'lastName',
		header: 'LAST_NAME',
		value: 'lastName',
		type: 'string',
		sortField: 'last_name',
		unit: 'conf_data',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		order: 3,
		required: true,
	},
	{
		label: 'firstName',
		header: 'FIRST_NAME',
		value: 'firstName',
		type: 'string',
		sortField: 'first_name',
		unit: 'conf_data',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		order: 4,
		required: true,
	},
	{
		label: 'confidential',
		header: 'CONFIDENTIAL_DATA',
		value: 'confidential',
		type: 'boolean',
		sortField: 'confidential',
		unit: null,
		defaultValue: false,
		sortable: false,
		hidden: false,
		searchable: true,
		order: 17,
		required: false,
	},
	{
		label: 'dateOfBirth',
		header: 'DATEOFBIRTH',
		value: 'dateOfBirth',
		type: 'date',
		sortField: 'date_of_birth',
		unit: 'date',
		defaultValue: [null],
		sortable: true,
		hidden: false,
		searchable: true,
		order: 6,
		required: true,
	},
	{
		label: 'imc',
		header: 'IMC',
		value: 'imc',
		type: 'string',
		sortField: 'imc',
		unit: null,
		defaultValue: '0',
		sortable: false,
		hidden: true,
		searchable: true,
		order: 11,
		required: true,
	},
	{
		label: 'confidentialityId',
		header: 'Conf.',
		value: 'confidentialityId',
		type: 'ID',
		sortField: 'confidentiality_id',
		unit: 'conf',
		defaultValue: 'U',
		sortable: false,
		hidden: false,
		searchable: true,
		order: 8,
		required: true,
	},
	{
		label: 'sc',
		header: 'SC',
		value: 'sc',
		type: 'string',
		sortField: 'sc',
		unit: 'Surface corporelle',
		defaultValue: '0',
		sortable: false,
		hidden: true,
		searchable: true,
		order: 12,
		required: true,
	},
	{
		label: 'createdDate',
		header: 'CREATED_DATE',
		value: 'createdDate',
		type: 'date',
		sortField: 'created_date',
		unit: 'date',
		defaultValue: [null],
		sortable: true,
		hidden: false,
		searchable: true,
		order: 13,
		required: true,
	},
];
