<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'EDITSPS' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button mat-dialog-close tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<mat-dialog-content>
	<div class="sps-form" [formGroup]="spsForm">
		<div class="exam-code">
			<div class="exam-label">Patient:</div>
			<div>
				{{ spsForm.getRawValue().patientName || '-' }}
			</div>
			<div class="exam-label">{{ 'EXAM' | translate }}:</div>
			<div>
				{{ spsForm.get('procedureCode').value.code || '-' }}
			</div>
		</div>

		<mat-divider></mat-divider>

		<div class="fx-layout-row-nowrap fx-gap-4" style="padding-top: 12px">
			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'START_DATE' | translate }}</mat-label>
				<input
					matInput
					[matDatepicker]="myDatepicker"
					placeholder="{{ 'START_DATE' | translate }}"
					formControlName="scheduledProcedureStepStartDate" />
				<mat-datepicker-toggle
					matSuffix
					[for]="myDatepicker"></mat-datepicker-toggle>
				<mat-datepicker #myDatepicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field class="fx-grow-1">
				<mat-label>{{ 'START_TIME' | translate }}</mat-label>
				<input
					matInput
					type="time"
					required
					placeholder="{{ 'START_TIME' | translate }}"
					formControlName="scheduledProcedureStepStartTime" />
			</mat-form-field>
		</div>
		<div class="fx-layout-row-nowrap fx-gap-4">
			<mat-form-field
				class="fx-grow-1"
				formGroupName="scheduledPerformingPhysiciansName">
				<mat-label>{{
					'PERFORMING_RADIOLOGIST' | translate
				}}</mat-label>
				<mat-select
					placeholder="{{ 'PERFORMING_RADIOLOGIST' | translate }}"
					formControlName="id">
					@for (stf of staffList; track stf) {
						<mat-option [value]="stf.id">
							{{ stf.fullName }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>

			<mat-form-field
				class="fx-grow-1"
				formGroupName="scheduledStationAETitle">
				<mat-label>{{ 'AET' | translate }}</mat-label>
				<mat-select
					placeholder="{{ 'AET' | translate }}"
					formControlName="id">
					@for (aet of aets; track aet) {
						<mat-option [value]="aet.id">
							{{ aet.title }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>
		</div>

		<mat-form-field class="fx-grow-1">
			<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
			<textarea
				matInput
				placeholder="{{ 'DESCRIPTION' | translate }}"
				formControlName="scheduledProcedureStepDescription"></textarea>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions class="fx-layout-row fx-content-start fx-gap-4">
	<button (click)="onSelectProcedure()" color="primary" mat-raised-button>
		<mat-icon fontIcon="mdi-pencil" fontSet="mdi"></mat-icon>
		{{ 'CHANGE_EXAM' | translate }}
	</button>

	<span class="fire-spacer"></span>

	<button [mat-dialog-close]="null" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="saveSPS()"
		mat-button
		color="primary"
		[disabled]="spsForm.invalid">
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
