import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientComponent } from './patient.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { UserResolver } from '../user.resolver';
import { PatientFolderComponent } from './patient-folder/patient-folder.component';

const routes: Routes = [
	{
		path: '',
		component: PatientComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'patients-list' },
			{
				path: 'patients-list',
				component: PatientListComponent,
				resolve: { user: UserResolver },
			},
			{ path: 'folder/:id', component: PatientFolderComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PatientRoutingModule {}
